import styled from "styled-components";

export const HR = styled.hr`
	margin: 0 auto;
	border: none;
	${(props) =>
		props.color
			? `border-bottom: 1px solid ${props.color};`
			: props.darkBg
				? `border-bottom: 1px solid #3f7868;`
				: `border-bottom: 1px solid var(--secondary-gray-medium);`}
`;

export const VR = styled.div`
	width: 0;
	min-height: 100%;
	margin: 0;
	padding: 0;
	${(props) =>
		props.color
			? `border-right: 1px solid ${props.color};`
			: props.darkBg
				? "border-right: 1px solid #3f7868;"
				: "border-right: 1px solid var(--secondary-gray-medium);"}
	opacity: ${(props) =>
		props.opacity ? props.opacity : props.color ? "1" : "0.2"};
`;
