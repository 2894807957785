import React from "react";
import { HR, VR } from "./styles";

const Separator = ({ darkBg, vertical, color, ...restProps }) => {
	if (vertical)
		return (
			<VR
				data-testid="vertical-seperator"
				aria-hidden="true"
				darkBg={darkBg}
				color={color}
				{...restProps}
			/>
		);
	else
		return (
			<HR
				data-testid="horizontal-seperator"
				aria-hidden="true"
				darkBg={darkBg}
				color={color}
				{...restProps}
			/>
		);
};

export default Separator;
